import React from 'react';
// import './App.css';


import FilialContainer from "./js/components/Filial/FilialContainer";
import DataContainer from "./js/components/Data/DataContainer";
import TimeTableContainer from "./js/components/TimeTable/TimeTableContainer";


function App() {

    return (


        <section>
            <FilialContainer/>
        </section>

    );
}

export default App;
