import {filials} from "../components/Filial/Filial";
const SET_VALUE = 'SET_VALUE'
const SET_TRAINING = 'SET_TRAINING'
let curent = '299';
const hash = window.location.hash ? window.location.hash.replace('#','') : '';
// for (let key in filials){
//     if(filials[key] == window.currentBranch){
//         curent = key;
//     }
// }
if (hash) {
    for (let key in filials) {
        if (filials[key].hash == hash) {
            curent = key;
        }
    }
}
const newCurent = filials[curent]
document.querySelector('#texo_schedule h1').innerHTML = newCurent.title+'<span>'+newCurent.addr+'</span>';

let initialState = {
    trainings: [],
    value: curent ? curent : '299'
}

const filialReducer = (state = initialState, action) => {

    switch (action.type) {
        case  SET_VALUE:

            return {
                ...state, value: action.value,

            }
        case   SET_TRAINING:

            return {
                ...state, trainings: action.trainings,

            }


        default :
            return state
    }
}


export const setValue = (value) => {

    return {
        type: SET_VALUE,
        value: value
    }
};


export default filialReducer


export const setTrainings = (value) => {

    return {
        type: SET_TRAINING,
        trainings: value
    }
};


