import React from "react";
import {connect} from "react-redux";
import * as axios from "axios";
import Data from "./Data";
import {endDay, setIndex, startDay} from "../../redux/data-reducer";
import {setTrainings} from "../../redux/filial-reducer";
import store from "../../redux/redux-store";
import Filial from "../Filial/Filial";
class DataContainer extends React.Component {

    constructor(props) {
        super(props);
        this.offsetDays = 0;
        console.log(this.props)
    }


    startData = (date) => {
        // store.dispatch( {
        //     type: 'START_DAY',
        //     start: date,
        // })
     this.props.startDay(date);
    };
    endData = (date) => {
       this.props.endDay(date);
        // store.dispatch( { 
        //     type: 'END_DAY',
        //     end: date,
        // })
    };

    changeWeek = (toNext) => {
        this.offsetDays += (toNext) ? 7 : -7;
        let date = new Date();

        date.setDate(date.getDate() + this.offsetDays);
        date.setDate(date.getDate() - date.getDay()); // move to the start of the week
        this.startData(date) // start day of the week

        let date2 = new Date(date.getTime());
        date2.setDate(date.getDate() + 6)
        this.endData(date2) // end day of the week

    }
    changeWeekPrev = (e) => {
        debugger
        e.preventDefault();
        this.changeWeek(false);
        console.log(this.props);
        let tempStart =  new Date(this.props.start.getTime());
        let tempEnd =  new Date(this.props.end.getTime());
        tempStart.setDate(tempStart.getDate()-7);
        tempEnd.setDate(tempEnd.getDate()-7);

        axios.post(`https://fithouse.co.il/arbox.php/`,
            {
                requestType: 'single_place',
                place_id: this.props.value,
                date_from: `${tempStart.getMonth()+1}/${tempStart.getDate()}/${tempStart.getFullYear()}`,
                date_to: `${tempEnd.getMonth()+1}/${tempEnd.getDate()}/${tempEnd.getFullYear()}`,
                // date_from: `04/${this.props.start}/2020`,
                // date_to: `04/${this.props.end}/2020`,


            }, {
                withCredentials: true

            }).then(response => {
            console.log(response.data)

            store.dispatch({
                type: 'SET_TRAINING',
                trainings: response.data
            })

        })
    };
    changeWeekNext = (e) => {
        e.preventDefault();
        this.changeWeek(true);
        let tempStart =  new Date(this.props.start.getTime());
        let tempEnd =  new Date(this.props.end.getTime());
        tempStart.setDate(tempStart.getDate()+7);
        tempEnd.setDate(tempEnd.getDate()+7);

        axios.post(`https://fithouse.co.il/arbox.php/`,
            {
                requestType: 'single_place',
                place_id: this.props.value,
                date_from: `${tempStart.getMonth()+1}/${tempStart.getDate()}/${tempStart.getFullYear()}`,
                date_to: `${tempEnd.getMonth()+1}/${tempEnd.getDate()+7}/${tempEnd.getFullYear()}`,
                // date_from: `04/12/2020`,
                // date_to: `04/18/2020`,


            }, {
                withCredentials: true

            }).then(response => {
            console.log(response.data)
            store.dispatch({
                type: 'SET_TRAINING',
                trainings: response.data
            })


        })

    }


    render() {
        console.log(this.props)
        return <Data
            dayWeeks={this.props.dayWeeks}
            changeWeekNext={this.changeWeekNext}
            changeWeekPrev={this.changeWeekPrev}
            start={this.props.start}
            end={this.props.end}
            setIndex={this.props.setIndex}
            activeIndex={this.props.activeIndex}
        />

    }
}

let mapStateToProps = (state) => {

    return {
        dayWeeks: state.dataFilter.dayWeeks,
        start: state.dataFilter.startDay,
        end: state.dataFilter.endDay,
        startDay: state.dataFilter.startDay,
        endDay: state.dataFilter.endDay,
        value: state.filterFilial.value,
        trainings: state.filterFilial.trainings,
       activeIndex: state.dataFilter.activeIndex
    };
};


export default connect(mapStateToProps, {startDay, setTrainings, endDay, setIndex })(DataContainer)
