import React from "react";

import TableTimeFilial from "./TableTimeFilial";
import DataContainer from "../Data/DataContainer";

let tempFunc = () => {

}

window.addEventListener("hashchange", ()=>{
    tempFunc(window.location.hash.replace('#',''));
}, false);



export const filials = window.branches ? window.branches : {
    // '1326' : 'אנילביץ, 62, תל-אביב',
    // '1459' : 'בן גוריון 11, אשקלון',
    // '1377' : 'ברנדייס 2, תל אביב',
    // '299' : 'ברנדייס 2, תל אביב',
    // '1592' : 'דרך הגליל 46, ראש-פינה',
    // '1295' : 'פורט ביתן 15 , נמל תל-אביב',
    // '1192' : 'פנחס רוזן 7 ,תל אביב',
    // '1327' : 'שינקין 47, תל-אביב'
};

let Filial = (props) => {
    console.log(props)
    let newSelectValue = React.createRef()
    let handleChange = () => {
        let text = newSelectValue.current.value;
        const newCurent = filials[text]
        document.querySelector('#texo_schedule h1').innerHTML= newCurent.title+'<span>'+newCurent.addr+'</span>';
        window.location.hash = newCurent.hash;
        props.setValue(text)
        props.changeFilial(text)
    }

    tempFunc = (hash) =>{
        for( let key in filials){
            if(filials[key].hash == hash){
                const newCurent = filials[key]
                document.querySelector('#texo_schedule h1').innerHTML= newCurent.title+'<span>'+newCurent.addr+'</span>';
                window.location.hash = newCurent.hash;
                props.setValue(key)
                props.changeFilial(key)
                break;
            }
        }
    }

    return <>
        <div className="scedual_title">

            <select id="storeFilter" ref={newSelectValue} value={props.value} onChange={handleChange}>
                {Object.entries(filials).
                sort((a,b)=>{
                    return a[1].order > b[1].order ? 1 : -1
                })
                .map((item) => {
                    return <option value={item[0]}>{item[1].option}</option>
                })}
                {/*<option value='1326'>אנילביץ, 62, תל-אביב</option>*/}
                {/*<option value="1459">בן גוריון 11, אשקלון</option>*/}
                {/*<option value="1377">ברנדייס 2, תל אביב</option>*/}
                {/*<option value='299'>דיזנגוף 158, תל אביב</option>*/}
                {/*<option value="1592">דרך הגליל 46, ראש-פינה</option>*/}
                {/*<option value="1295">פורט ביתן 15 , נמל תל-אביב</option>*/}
                {/*<option value="1192">פנחס רוזן 7 ,תל אביב</option>*/}
                {/*<option value="1327">שינקין 47, תל-אביב</option>*/}
            </select>
        </div>
        <div className='calander_section'>
            <div className='small_screen_scroll'>
                <DataContainer/>
                <TableTimeFilial startDay={props.startDay}
                                 endDay={props.endDay}
                                 trainings={props.trainings}
                                 dayWeeks={props.dayWeeks}
                                 activeIndex={props.activeIndex}
                />
            </div>
        </div>


    </>

}



export default Filial
