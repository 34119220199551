import React from "react";
import Training from "./Training/Training";
import Filial from "./Filial";


let TableTimeFilial = (props) => {
    console.log(props);
    let month = props.startDay.getMonth() + 1 <= 9 ? '0' + (props.startDay.getMonth() + 1) : props.startDay.getMonth() + 1;
    let date = props.startDay.getDate() <= 9 ? '0' + props.startDay.getDate() : props.startDay.getDate();
    let year = props.startDay.getFullYear();
    let start = `${year}-${month}-${date}`;
    console.log(start);
    let moveDate = (num) => {
        let date = new Date(props.startDay);
        date.setDate(date.getDate() + num);
        let month = String(date.getMonth() + 1).padStart(2, '0');
        let day = String(date.getDate()).padStart(2, '0');
        let year = date.getFullYear();
        return `${year}-${month}-${day}`;
    }
    let compareNumbers = (a, b) => {
        if (a.date < b.date) {
            return -1;
        }
        if (a.date > b.date) {
            return 1;
        }
        return 0;

    };
    let arr = [];
    let l = 0;
    let trainings = props.trainings.sort((a, b) => {
        if (a.time > b.time) {
            return 1;
        }
        if (a.time < b.time) {
            return -1;
        }
        return 0;
    }).filter(el => el.status === 'active')

    let days = props.dayWeeks.map((el, i) => {
        let start = moveDate(i);
        let temp = trainings.filter((item) => {
            if (start == item.date) {
                return item
            }
        })
        if (temp.length > l) {
            l = temp.length;
        }
        return temp;
    });
    days = days.map((item) => {
        if (item.length < l) {
            let oldL = item.length
            item.length = l;
            return item.fill({}, oldL, l);
        }
        return item;
    })
    console.log(days);
    setTimeout(function () {
        scroll && scroll.update && scroll.update()
    })
    return <div className="calander_body">


        {days.map((el, i) => {

                return <div className="date_row">
                    <div className="calander_wrapper">
                        {
                            el.map((item) => {

                                return <Training training={item} length={l}/>
                            })
                        }
                    </div>

                </div>

        })


        }


        {/*{props.dayWeeks.map((el, i) => {*/}
        {/*    let start = moveDate(i);*/}
        {/*    return <div className="date_row">*/}
        {/*        <div className="calander_wrapper">*/}
        {/*            {*/}
        {/*                trainings.map((item) => {*/}

        {/*                    if (start == item.date) {*/}
        {/*                        return <Training training={item} length={l}/>*/}
        {/*                    }*/}
        {/*                })*/}
        {/*            }*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*})*/}


        {/*}*/}

    </div>
}

export default TableTimeFilial
