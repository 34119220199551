import React from "react";
import {connect} from "react-redux";
import * as axios from "axios";
import Filial from "./Filial";
import {setTrainings, setValue} from "../../redux/filial-reducer";

class FilialContainer extends React.Component {

    constructor(props) {
        super(props);

    }


    componentDidMount() {

        axios.post(`https://fithouse.co.il/arbox.php/`,
            {
                requestType: 'single_place',
                place_id: this.props.value,
                date_from: `${this.props.startDay.getMonth()+1}/${this.props.startDay.getDate()}/${this.props.startDay.getFullYear()}`,
                date_to: `${this.props.endDay.getMonth()+1}/${this.props.endDay.getDate()}/${this.props.endDay.getFullYear()}`,

            }, {
                withCredentials: true

            }).then(response => {
            console.log(response.data)

            this.props.setTrainings(response.data)

        })

        // console.log(result)
    }


    changeFilial = (id) => {

        axios.post(`https://fithouse.co.il/arbox.php/`,
            {
                requestType: 'single_place',
                // place_id: this.props.value,
                place_id: id,
                date_from: `${this.props.startDay.getMonth()+1}/${this.props.startDay.getDate()}/${this.props.startDay.getFullYear()}`,
                date_to: `${this.props.endDay.getMonth()+1}/${this.props.endDay.getDate()}/${this.props.endDay.getFullYear()}`,

            }, {
                withCredentials: true

            }).then(response => {
            console.log(response.data)

            this.props.setTrainings(response.data)
        })

    }

    render() {
        console.log(this.props)
        return <>
            <Filial
                value={this.props.value}
                setValue={this.props.setValue}
                changeFilial={this.changeFilial}
                trainings={this.props.trainings}
                startDay={this.props.startDay}
                endDay={this.props.endDay}
                dayWeeks={this.props.dayWeeks}
                activeIndex={this.props.activeIndex}
            />
        </>
    }
}

let mapStateToProps = (state) => {

    return {
        value: state.filterFilial.value,
        trainings: state.filterFilial.trainings,
        startDay: state.dataFilter.startDay,
        endDay: state.dataFilter.endDay,
        dayWeeks: state.dataFilter.dayWeeks,
        activeIndex: state.dataFilter.activeIndex
    };
};


export default connect(mapStateToProps, {setValue, setTrainings})(FilialContainer)
