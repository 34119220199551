// const SET_DAY_WEEK='SET_DAY_WEEK'
const START_DAY = 'START_DAY';
const END_DAY = 'END_DAY';
const ACTIVE_INDEX = 'ACTIVE_INDEX'
let startDate = new Date()

let endDate = new Date()
startDate.setDate(startDate.getDate() - startDate.getDay())
endDate.setDate(endDate.getDate() - endDate.getDay() + 6)

let initialState = {
    dayWeeks: ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'],
    startDay: startDate,
    endDay: endDate,
    activeIndex: 0
}

const dataReducer = (state = initialState, action) => {

    switch (action.type) {
        case START_DAY:
            debugger
            return {
                ...state, startDay: action.start,
            };
        case END_DAY:
            return {
                ...state, endDay: action.end
            };
        case ACTIVE_INDEX:
            return {
                ...state, activeIndex: action.index
            };
        default :
            return state
    }
}
export const startDay = (day, date) => {
    return {
        type: START_DAY,
        start: day,
    }
};
export const setIndex = (index) => {
    return {
        type: ACTIVE_INDEX,
        index,
    }
};
export const endDay = (day) => {
    return {
        type: END_DAY,
        end: day,
    }
};


export default dataReducer
