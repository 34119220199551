import {combineReducers, createStore} from "redux";
import filialReducer from "./filial-reducer";
import dataReducer from "./data-reducer";




let reducers = combineReducers({

    filterFilial:filialReducer,
    dataFilter: dataReducer,


});


let store =createStore(reducers,{}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
export default store