import React from "react";
import {connect} from "react-redux";
import {endDay, startDay} from "../../redux/data-reducer";
import store from "../../redux/redux-store";

let d = new Date()

let Data = (props) => {
console.log(props)

    let start = props.start.getDate()
    let end1 = props.end.getDate()

    let getNextDay = (daysOffset) => {
        let interval = 7;
        let limit = (props.end.getDate() < props.start.getDate()) ? (interval - props.end.getDate()) : interval;
        return (daysOffset < limit) ? (props.start.getDate() + daysOffset) : (daysOffset - limit + 1);
    }
    let classActive = 'list'  
    let setActiveIndex=(i)=>{
        props.setIndex(i)
        classActive = props.activeIndex ? "list active" :"list"
        return classActive
    }
    return <div className="calander_title"> 

        <div className="date_filter">
            <div  style={{display: 'flex' , justifyContent: 'center',     alignItems: 'center'}}><a href={"#"} className="prevweek" onClick={props.changeWeekNext}>&lt;</a>
                <div>  <span>{props.start.getDate()}</span>  <b>{`${' ראשון - '}`}</b></div>
               <div> <span> {props.end.getDate()}</span> <b>{`${'שבת'}`}</b></div>

                <a href={"#"} onClick={props.changeWeekPrev} className="nextweek">&gt;</a>
            </div>

        </div>
        <div className="inner_wrapper">
            {props.dayWeeks.map((d, i) => {
                return <div className={classActive} onClick={()=>setActiveIndex(i) }>
                    <h4>{d}</h4>
                    <p>{getNextDay(i)}</p>
                </div>
            })}
        </div>
    </div>


}

export default Data


