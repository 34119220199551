import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import store from './js/redux/redux-store'
import App from './App';
import {Provider} from "react-redux";


ReactDOM.render(

        <Provider store={store}>

            <App  />
        </Provider>,

    document.getElementById('root')
);


// eslint-disable-next-line no-undef

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

window.store = store
